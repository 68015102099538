import React from 'react';
import { format, parseISO } from 'date-fns';
import { Card, CardContent,Typography } from '@mui/material';

interface OKR {
  _id: string; // Ensure the _id field is included
  Goal: string;
  TimePeriod?: string;
  Comments?: string;
  Status: string;
}

interface MyOKRTileProps {
  okr: OKR;
  onClick: () => void; // Add this line
}

const MyOKRTile: React.FC<MyOKRTileProps> = ({ okr, onClick }) => {
  // Helper function to format the date
  const formatDate = (dateString: string): string => {
    const date = parseISO(dateString);
    return format(date, 'd-MMM-yyyy');
  };

  return (
    <Card
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        height: '150px', 
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" gutterBottom noWrap>
          {okr.Goal}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <strong>Deadline:</strong>{' '}
          {okr.TimePeriod ? formatDate(okr.TimePeriod) : 'No deadline'}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3, // Limits to 3 lines
            WebkitBoxOrient: 'vertical',
          }}
        >
          <strong>Comments:</strong> {okr.Comments || 'No comments'}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MyOKRTile;
