// src/pages/FocusOKR.tsx

import React, { useState, useEffect } from 'react';
import { OKR } from '../types';
import FokusOKRTile from '../components/FokusOKRTiles';
import {
  Typography,
  Box,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import supabase from '../Auth/supabase';

interface Team {
  name: string;
}

interface User {
  name: string;
  assigneeId: string;
}

interface FocusOKRProps {
  okrs: OKR[];
}

const FocusOKR: React.FC<FocusOKRProps> = ({ okrs }) => {
  const [filterType, setFilterType] = useState<string>('All');
  const [teams, setTeams] = useState<Team[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [timeFilterType, setTimeFilterType] = useState<string>('All');
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedOKR, setSelectedOKR] = useState<OKR | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [filteredOkrs, setFilteredOkrs] = useState<OKR[]>([]);
  const [allOkrs, setAllOkrs] = useState<OKR[]>([]); // New state to hold all OKRs

  const excludedGoalTypes = ['Personal'];

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

  // Fetch teams, users, and OKRs
  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession();
        const token = session?.access_token;

        if (token) {
          // Fetch teams
          const teamsResponse = await axios.get<Team[]>(`${apiBaseUrl}/api/teams`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setTeams(teamsResponse.data);

          // Fetch users
          const usersResponse = await axios.get<User[]>(`${apiBaseUrl}/api/okr-owners`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUsers(usersResponse.data);

          // Fetch all OKRs
          const okrsResponse = await axios.get<OKR[]>(`${apiBaseUrl}/api/okrs`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setAllOkrs(okrsResponse.data);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError('Failed to fetch data');
      }
    };

    fetchData();
  }, []);

  // Apply filters
  useEffect(() => {
    let filtered = allOkrs.filter((okr) => !excludedGoalTypes.includes(okr.GoalType));

    // Filter for Red and Amber statuses
    filtered = filtered.filter((okr) => okr.Status === 'Red' || okr.Status === 'Amber');

    if (filterType === 'Company') {
      filtered = filtered.filter((okr) => okr.GoalType === 'Company');
    } else if (filterType === 'Team') {
      if (selectedTeam) {
        filtered = filtered.filter((okr) => okr.Team === selectedTeam);
      }
    } else if (filterType === 'Individual') {
      if (selectedUser) {
        filtered = filtered.filter(
          (okr) => okr.AssigneeId === selectedUser || okr.AssigneeName === selectedUser
        );
      }
    }

    if (timeFilterType === 'Custom' && fromDate && toDate) {
      filtered = filtered.filter((okr) => {
        const deadline = new Date(okr.TimePeriod);
        return deadline >= fromDate && deadline <= toDate;
      });
    }

    setFilteredOkrs(filtered);
  }, [
    allOkrs,
    filterType,
    selectedTeam,
    selectedUser,
    timeFilterType,
    fromDate,
    toDate,
  ]);

  // Separate red and amber OKRs
  const redOkrs = filteredOkrs.filter((okr) => okr.Status === 'Red');
  const amberOkrs = filteredOkrs.filter((okr) => okr.Status === 'Amber');

  // Handler to open the modal with selected OKR
  const handleOKRClick = (okr: OKR) => {
    setSelectedOKR(okr);
    setOpenModal(true);
  };

  // Handler to close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOKR(null);
  };

  // Handler to save the updated OKR
  const handleSaveOKR = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token && selectedOKR) {
        const { _id, ...okrData } = selectedOKR;
        const updateData = {
          Goal: okrData.Goal,
          AssigneeId: okrData.AssigneeId,
          AssigneeName: okrData.AssigneeName,
          Team: okrData.Team,
          Status: okrData.Status,
          TimePeriod: okrData.TimePeriod,
          Comments: okrData.Comments,
        };

        await axios.put(`${apiBaseUrl}/api/okrs/${_id}`, updateData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Refresh OKRs after update
        await fetchUpdatedOKRs();
        handleCloseModal();
      }
    } catch (error) {
      console.error('Failed to update OKR:', error);
      setError('Failed to update OKR');
    }
  };

  // Function to fetch updated OKRs
  const fetchUpdatedOKRs = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token) {
        const okrsResponse = await axios.get<OKR[]>(`${apiBaseUrl}/api/okrs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAllOkrs(okrsResponse.data);
      }
    } catch (error) {
      console.error('Failed to fetch updated OKRs:', error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Filter Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
        <Typography variant="body1" sx={{ mr: 1 }}>
          Filter by:
        </Typography>

        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={filterType}
            onChange={(e) => {
              setFilterType(e.target.value);
              setSelectedTeam('');
              setSelectedUser('');
            }}
            label="Type"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Company">Company</MenuItem>
            <MenuItem value="Team">Team</MenuItem>
            <MenuItem value="Individual">Individual</MenuItem>
          </Select>
        </FormControl>

        {filterType === 'Team' && (
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
            <InputLabel>Select Team</InputLabel>
            <Select
              value={selectedTeam}
              onChange={(e) => setSelectedTeam(e.target.value)}
              label="Select Team"
            >
              {teams.map((team, index) => (
                <MenuItem key={index} value={team.name}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {filterType === 'Individual' && (
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
            <InputLabel>Select User</InputLabel>
            <Select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              label="Select User"
            >
              {users.map((user, index) => (
                <MenuItem key={index} value={user.assigneeId}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Time Period</InputLabel>
          <Select
            value={timeFilterType}
            onChange={(e) => {
              setTimeFilterType(e.target.value);
              if (e.target.value === 'All') {
                setFromDate(null);
                setToDate(null);
              }
            }}
            label="Time Period"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
        </FormControl>

        {timeFilterType === 'Custom' && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="From"
              value={fromDate}
              onChange={(newValue) => setFromDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
            <DatePicker
              label="To"
              value={toDate}
              onChange={(newValue) => setToDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </LocalizationProvider>
        )}
      </Box>

      {/* Display OKRs */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ color: '#f44336' }}>
              Red OKRs
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {redOkrs.length > 0 ? (
                redOkrs.map((okr, index) => (
                  <Grid item xs={12} key={index}>
                    <FokusOKRTile okr={okr} onClick={() => handleOKRClick(okr)} />
                  </Grid>
                ))
              ) : (
                <Typography>No red OKRs found.</Typography>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ color: '#ff9800' }}>
              Amber OKRs
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {amberOkrs.length > 0 ? (
                amberOkrs.map((okr, index) => (
                  <Grid item xs={12} key={index}>
                    <FokusOKRTile okr={okr} onClick={() => handleOKRClick(okr)} />
                  </Grid>
                ))
              ) : (
                <Typography>No amber OKRs found.</Typography>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* Modal for OKR details */}
      {selectedOKR && (
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Edit OKR</DialogTitle>
          <DialogContent>
            <TextField
              label="Goal"
              name="Goal"
              value={selectedOKR.Goal}
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  Goal: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2 }}
            />

            {/* Owner Selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Owner</InputLabel>
              <Select
                value={selectedOKR.AssigneeId || ''}
                onChange={(e) => {
                  const selectedUser = users.find(
                    (user) => user.assigneeId === e.target.value
                  );
                  setSelectedOKR({
                    ...selectedOKR!,
                    AssigneeId: e.target.value as string,
                    AssigneeName: selectedUser ? selectedUser.name : '',
                  });
                }}
                label="Owner"
              >
                {users.map((user) => (
                  <MenuItem key={user.assigneeId} value={user.assigneeId}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Team Selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Team</InputLabel>
              <Select
                value={selectedOKR.Team || ''}
                onChange={(e) =>
                  setSelectedOKR({
                    ...selectedOKR!,
                    Team: e.target.value as string,
                  })
                }
                label="Team"
              >
                {teams.map((team, index) => (
                  <MenuItem key={index} value={team.name}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={selectedOKR.Status || ''}
                onChange={(e) =>
                  setSelectedOKR({
                    ...selectedOKR!,
                    Status: e.target.value as string,
                  })
                }
                label="Status"
              >
                <MenuItem value="Green">Green</MenuItem>
                <MenuItem value="Amber">Amber</MenuItem>
                <MenuItem value="Red">Red</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Deadline"
              name="TimePeriod"
              type="date"
              value={
                selectedOKR.TimePeriod
                  ? new Date(selectedOKR.TimePeriod).toISOString().split('T')[0]
                  : ''
              }
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  TimePeriod: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Comments"
              name="Comments"
              value={selectedOKR.Comments || ''}
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  Comments: e.target.value,
                })
              }
              fullWidth
              multiline
              rows={4}
              sx={{ mb: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button onClick={handleSaveOKR} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};



export default FocusOKR;
