// src/components/FokusOKRTiles.tsx

import React from 'react';
import { OKR } from '../types';
import { Card, CardContent, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';


interface FokusOKRTileProps {
  okr: OKR;
  onClick?: () => void; // Add this line
}

const FokusOKRTile: React.FC<FokusOKRTileProps> = ({ okr, onClick }) => {
  const formatDate = (dateString: string): string => {
    const date = parseISO(dateString);
    return format(date, 'd-MMM-yyyy');
  };

  return (
    <Card
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {okr.Goal}
        </Typography>

        {okr.AssigneeName && okr.AssigneeName !== 'Unassigned' ? (
          <Typography variant="body2" color="textSecondary">
            Owner: {okr.AssigneeName}
          </Typography>
        ) : null}

        {okr.Team && (
          <Typography variant="body2" color="textSecondary">
            Team: {okr.Team}
          </Typography>
        )}

        {okr.TimePeriod && (
          <Typography variant="body2" color="textSecondary">
            Deadline: {formatDate(okr.TimePeriod)}
          </Typography>
        )}

        {okr.Comments && (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
            }}
          >
            Comment: {okr.Comments}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};


export default FokusOKRTile;
