// src/components/PieChart.tsx
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const COLORS = {
  Green: '#00C49F',
  Amber: '#FFBB28',
  Red: '#FF8042',
};

interface CustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
  payload: { name: string; value: number };
}

interface OKR {
  Status?: string; // Status is optional
}

interface StatusPieChartProps {
  okrs: OKR[];
}

const CustomizedLabel: React.FC<CustomizedLabelProps> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  payload,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontSize="14"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const StatusPieChart: React.FC<StatusPieChartProps> = ({ okrs }) => {
  const data = [
    { name: 'Green', value: okrs.filter((okr) => okr.Status === 'Green').length },
    { name: 'Amber', value: okrs.filter((okr) => okr.Status === 'Amber').length },
    { name: 'Red', value: okrs.filter((okr) => okr.Status === 'Red').length },
  ];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={CustomizedLabel}
          outerRadius={120}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[entry.name as keyof typeof COLORS]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default StatusPieChart;
