// src/App.tsx

import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import Sidebar from './components/Navbar';
import Dashboard from './Pages/Dashboard';
import OKRRelationships from './Pages/OKRRelationship';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import MyOKRs from './Pages/MyOKRs';
import LoginPage from './LoginPage';
import PrivateRoute from './PrivateRoute';
import SignUp from './Auth/Signup';
import ForgotPassword from './Auth/ForgotPassword';
import PasswordResetSuccess from './Auth/PasswordResetSuccess';
import ResetPassword from './Auth/ResetPassword';
import supabase from './Auth/supabase';
import TopBar from './components/TopBar';
import { Box } from '@mui/material';
import Layout from './Layout';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [authCompleted, setAuthCompleted] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      setIsAuthenticated(!!session);
      setAuthCompleted(true);
    };

    checkSession();

    const {
      data: authListener,
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      setIsAuthenticated(!!session);
      setAuthCompleted(true);
      if (!session) {
        navigate('/login');
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const logout = async () => {
    await supabase.auth.signOut();
    localStorage.removeItem('authToken');
    navigate('/login');
    window.location.reload(); // Reload the page to clear the cached data
  };

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/password-reset-success"
          element={<PasswordResetSuccess />}
        />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Layout logout={logout} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/my-okrs" element={<MyOKRs />} />
            <Route
              path="/okr-relationships"
              element={<OKRRelationships />}
            />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
