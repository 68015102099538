import React, { useState, useEffect } from 'react';
import axios from 'axios';
import supabase from '../Auth/supabase';
import '../components/MyOKRs.css';
import MyOKRTile from '../components/MyOKRTile';
import { Box, Typography, Card, CardContent, Grid,   Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button,FormControl, InputLabel,Select, MenuItem,} from '@mui/material';
import colors from '../components/color'; 
import AccessTimeIcon from '@mui/icons-material/AccessTime'; 
import ErrorIcon from '@mui/icons-material/Error'; 


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

interface OKR {
    _id: string; 
    Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

const MyOKRs: React.FC = () => {
  const [userOKRs, setUserOKRs] = useState<OKR[]>([]);
  const [upcomingOKRs, setUpcomingOKRs] = useState<OKR[]>([]);
  const [overdueOKRs, setOverdueOKRs] = useState<OKR[]>([]);
  const [teamOKRs, setTeamOKRs] = useState<OKR[]>([]);
  const [teamName, setTeamName] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
const [selectedOKR, setSelectedOKR] = useState<OKR | null>(null);

  const isUpcomingOrOverdue = (deadlineString: string): { overdue: boolean; upcoming: boolean } => {
    const today = new Date();
    const deadline = new Date(deadlineString);
    const timeDiff = deadline.getTime() - today.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    return {
      overdue: daysDiff < 0,
      upcoming: daysDiff >= 0 && daysDiff <= 14,
    };
  };

    const fetchOKRs = async () => {
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession();
        const token = session?.access_token;

        if (token) {
          // Fetch individual OKRs
          const individualResponse = await axios.get(`${apiBaseUrl}/api/okrs?role=individual`, {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            },
          });

          // Fetch team OKRs
          const teamResponse = await axios.get(`${apiBaseUrl}/api/okrs?role=team`, {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            },
          });

          if (Array.isArray(individualResponse.data) && Array.isArray(teamResponse.data)) {
            // **Filter out OKRs with Status "Completed" or "Failed"**
            const filteredIndividualOkrs = individualResponse.data.filter(
              (okr: OKR) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
            );
            const filteredTeamOkrs = teamResponse.data.filter(
              (okr: OKR) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
            );

            // Use the filtered OKRs
            setUserOKRs(filteredIndividualOkrs);
            setTeamOKRs(filteredTeamOkrs);

            // For deadlines
            const upcomingOkrs = filteredIndividualOkrs.filter(
              (okr) => okr.TimePeriod && isUpcomingOrOverdue(okr.TimePeriod).upcoming
            );
            const overdueOkrs = filteredIndividualOkrs.filter(
              (okr) => okr.TimePeriod && isUpcomingOrOverdue(okr.TimePeriod).overdue
            );

            setUpcomingOKRs(upcomingOkrs);
            setOverdueOKRs(overdueOkrs);

            // Extract the team name from the first OKR in the filtered team OKRs list
            if (filteredTeamOkrs.length > 0) {
              setTeamName(filteredTeamOkrs[0].Team);
            }
          } else {
            setError('Error fetching OKRs');
          }
        } else {
          setError('No auth token found');
        }
      } catch (error) {
        console.error('Failed to fetch OKRs:', error);
        setError('Failed to fetch OKRs');
      }
    };
    useEffect(() => {
    fetchOKRs();
  }, []);

  const handleOKRClick = (okr: OKR) => {
    setSelectedOKR(okr);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOKR(null);
  };

  const handleSaveOKR = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token && selectedOKR) {
        const { _id, ...okrData } = selectedOKR;
        const updateData = {
          Goal: okrData.Goal,
          AssigneeName: okrData.AssigneeName,
          Status: okrData.Status,
          TimePeriod: okrData.TimePeriod,
          Comments: okrData.Comments,
        };

        await axios.put(
          `${apiBaseUrl}/api/okrs/${_id}`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Refresh OKRs after update
        await fetchOKRs();
        handleCloseModal();
      }
    } catch (error) {
      console.error('Failed to update OKR:', error);
      setError('Failed to update OKR');
    }
  };


  if (error) {
    return <div>{error}</div>;
  }


  const groupOKRsByStatus = (okrs: OKR[]) => {
    return {
      green: okrs.filter((okr) => okr.Status === 'Green'),
      amber: okrs.filter((okr) => okr.Status === 'Amber'),
      red: okrs.filter((okr) => okr.Status === 'Red'),
      noStatus: okrs.filter((okr) => !okr.Status),
    };
  };

  const { green, amber, red, noStatus } = groupOKRsByStatus(userOKRs);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Box sx={{ flexGrow: 1, padding: '20px' }}>
      <Grid container spacing={3}>
        {/* Left column for OKRs list */}
        <Grid item xs={12} md={8}>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                My OKRs
              </Typography>

              <Typography variant="h6" sx={{ color: colors.Green }}>
                Green OKRs
              </Typography>
              <Grid container spacing={2}>
                {green.length > 0 ? (
                  green.map((okr, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <MyOKRTile okr={okr} onClick={() => handleOKRClick(okr)} />
                    </Grid>
                  ))
                ) : (
                  <Typography>No Green OKRs found.</Typography>
                )}
              </Grid>

              <Typography variant="h6" sx={{ color: colors.Amber, mt: 3 }}>
                Amber OKRs
              </Typography>
              <Grid container spacing={2}>
                {amber.length > 0 ? (
                  amber.map((okr, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <MyOKRTile okr={okr} onClick={() => handleOKRClick(okr)} />
                    </Grid>
                  ))
                ) : (
                  <Typography>No Amber OKRs found.</Typography>
                )}
              </Grid>

              <Typography variant="h6" sx={{ color: colors.Red, mt: 3 }}>
                Red OKRs
              </Typography>
              <Grid container spacing={2}>
                {red.length > 0 ? (
                  red.map((okr, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <MyOKRTile okr={okr} onClick={() => handleOKRClick(okr)} />
                    </Grid>
                  ))
                ) : (
                  <Typography>No Red OKRs found.</Typography>
                )}
              </Grid>

              <Typography variant="h6" sx={{ mt: 3, ml: 2 }}>
                No Status OKRs
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1, ml: 2 }}>
                {noStatus.length > 0 ? (
                  noStatus.map((okr, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <MyOKRTile okr={okr} onClick={() => handleOKRClick(okr)} />
                    </Grid>
                  ))
                ) : (
                  <Typography sx={{ mt: 2 }}>No OKRs without status updates found.</Typography>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Right column for Deadlines */}
        <Grid item xs={12} md={4}>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                Deadlines
              </Typography>

              <Typography
                variant="h6"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <ErrorIcon sx={{ mr: 1 }} color="error" /> Overdue OKRs
              </Typography>
              {overdueOKRs.length > 0 ? (
                overdueOKRs.map((okr, index) => (
                  <Typography
                    key={index}
                    onClick={() => handleOKRClick(okr)}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      mb: 1,
                    }}
                  >
                    {okr.Goal}
                  </Typography>
                ))
              ) : (
                <Typography>No overdue OKRs found.</Typography>
              )}

              <Typography
                variant="h6"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 2,
                }}
              >
                <AccessTimeIcon sx={{ mr: 1 }} color="primary" /> Upcoming
                Deadlines
              </Typography>
              {upcomingOKRs.length > 0 ? (
                upcomingOKRs.map((okr, index) => (
                  <Typography
                    key={index}
                    onClick={() => handleOKRClick(okr)}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      mb: 1,
                    }}
                  >
                    {okr.Goal}
                  </Typography>
                ))
              ) : (
                <Typography>No upcoming deadlines found.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Modal for OKR details */}
      {selectedOKR && (
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Edit OKR</DialogTitle>
          <DialogContent>
            <TextField
              label="Goal"
              name="Goal"
              value={selectedOKR.Goal}
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  Goal: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={selectedOKR.Status || ''}
                onChange={(e) =>
                  setSelectedOKR({
                    ...selectedOKR!,
                    Status: e.target.value as string,
                  })
                }
                label="Status"
              >
                <MenuItem value="Green">Green</MenuItem>
                <MenuItem value="Amber">Amber</MenuItem>
                <MenuItem value="Red">Red</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Deadline"
              name="TimePeriod"
              type="date"
              value={
                selectedOKR.TimePeriod
                  ? new Date(selectedOKR.TimePeriod)
                      .toISOString()
                      .split('T')[0]
                  : ''
              }
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  TimePeriod: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Comments"
              name="Comments"
              value={selectedOKR.Comments || ''}
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  Comments: e.target.value,
                })
              }
              fullWidth
              multiline
              rows={4}
              sx={{ mb: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button onClick={handleSaveOKR} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};



export default MyOKRs;
