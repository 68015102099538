
import React, { useState, useEffect } from 'react';
import Tree, { RenderCustomNodeElementFn, RawNodeDatum } from 'react-d3-tree';
import supabase from '../Auth/supabase';
import { Box, Typography } from '@mui/material';
import theme from 'theme';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

interface OKR {
  _id: string;
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

interface TreeNode {
  name: string;
  _id?: string;
  ParentGoalId?: string;
  attributes?: {
    type: string;
    status: string;
    owner?: string;
    team?: string;
  };
  collapsed?: boolean;
  children?: TreeNode[];
}

function transformOKRs(okrs: OKR[]): TreeNode {
  const activeOKRs = okrs.filter(
    (okr) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
  );

  const companyOKRs = activeOKRs.filter(
    (okr) => okr.GoalType === 'Company' && okr.Goal
  );
  const teamOKRs = activeOKRs.filter((okr) => okr.GoalType === 'Team');

  const structuredCompanyOKRs: { [id: string]: TreeNode } = {};
  companyOKRs.forEach((okr) => {
    const okrId = String(okr._id);
    structuredCompanyOKRs[okrId] = {
      name: okr.Goal,
      _id: okrId,
      ParentGoalId: okr.ParentGoalId ? String(okr.ParentGoalId) : undefined,
      children: [],
      attributes: {
        type: okr.GoalType,
        status: okr.Status,
        owner: okr.AssigneeName,
        team: okr.Team,
      },
      collapsed: false,
    };
  });

  const structuredTeamOKRs: { [id: string]: TreeNode } = {};
  teamOKRs.forEach((okr) => {
    const okrId = String(okr._id);
    structuredTeamOKRs[okrId] = {
      name: okr.Goal,
      _id: okrId,
      ParentGoalId: okr.ParentGoalId ? String(okr.ParentGoalId) : undefined,
      attributes: {
        type: okr.GoalType,
        status: okr.Status,
        owner: okr.AssigneeName,
        team: okr.Team,
      },
      collapsed: false,
    };
  });

  Object.values(structuredTeamOKRs).forEach((teamNode) => {
    const parentId = teamNode.ParentGoalId;
    if (parentId && structuredCompanyOKRs[parentId]) {
      structuredCompanyOKRs[parentId].children!.push(teamNode);
    } else {
      console.warn(
        `Team OKR with id ${teamNode._id} has invalid ParentGoalId ${parentId}`
      );
    }
  });

  const rootNode: TreeNode = {
    name: 'All OKRs',
    _id: 'root',
    children: Object.values(structuredCompanyOKRs),
    attributes: {
      type: 'Root',
      status: '',
      owner: '',
      team: '',
    },
    collapsed: false,
  };

  return rootNode;
}

function OKRRelationships() {
  const [okrs, setOkrs] = useState<TreeNode | null>(null);
  const [okrDataById, setOkrDataById] = useState<Map<string, OKR>>(new Map());
  const [error, setError] = useState<string | null>(null);
  const [selectedNodes, setSelectedNodes] = useState<{ [key: string]: boolean }>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedOKR, setSelectedOKR] = useState<OKR | null>(null);

  const fetchOKRs = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token) {
        const response = await fetch(`${apiBaseUrl}/api/okrs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        // Create a mapping from OKR IDs to OKR data
        const okrDataMap = new Map<string, OKR>();
        data.forEach((okr: OKR) => {
          okrDataMap.set(okr._id, okr);
        });
        setOkrDataById(okrDataMap);

        const treeData = transformOKRs(data);
        setOkrs(treeData);
      } else {
        console.error('No auth token found');
        setError('No auth token found');
      }
    } catch (error) {
      console.error('Failed to fetch OKRs:', error);
      setError('Failed to fetch OKRs');
    }
  };

  useEffect(() => {
    fetchOKRs();
  }, []);

  const handleNodeClick = (nodeDatum: RawNodeDatum) => {
    const customNode = nodeDatum as TreeNode;

    if (customNode._id && customNode._id !== 'root') {
      const okr = okrDataById.get(customNode._id);
      if (okr) {
        setSelectedOKR(okr);
        setOpenModal(true);
      }
    }

    // Existing code to handle selected nodes (if any)
    const newSelectedNodes: { [key: string]: boolean } = {};
    if (customNode.attributes?.type === 'Company') {
      newSelectedNodes[customNode._id || ''] = true;
    } else if (customNode.attributes?.type === 'Team') {
      newSelectedNodes[customNode._id || ''] = true;
      if (customNode.ParentGoalId) {
        newSelectedNodes[customNode.ParentGoalId] = true;
      }
    }
    setSelectedNodes(newSelectedNodes);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOKR(null);
  };

  const handleSaveOKR = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      const token = session?.access_token;

      if (token && selectedOKR) {
        const { _id, ...okrData } = selectedOKR;
        const updateData = {
          Goal: okrData.Goal,
          AssigneeName: okrData.AssigneeName,
          Status: okrData.Status,
          TimePeriod: okrData.TimePeriod,
          Comments: okrData.Comments,
        };

        await axios.put(
          `${apiBaseUrl}/api/okrs/${_id}`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Refresh OKRs after update
        await fetchOKRs();
        handleCloseModal();
      }
    } catch (error) {
      console.error('Failed to update OKR:', error);
      setError('Failed to update OKR');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  const renderNode: RenderCustomNodeElementFn = ({ nodeDatum }) => {
    const customNode = nodeDatum as TreeNode;
    const { type = 'Root', status = '', owner = '', team = '' } =
      customNode.attributes || {};

    const statusColors: { [key: string]: string } = {
      Green: '#4caf50',
      Amber: '#ff9800',
      Red: '#f44336',
    };

    const borderColor = selectedNodes[customNode._id || '']
      ? '#f44336'
      : type === 'Company'
      ? '#0d47a1'
      : type === 'Team'
      ? '#388e3c'
      : '#555555';

    const yPosition = type === 'Company' ? -40 : -70;

    return (
      <g className="tree-node" onClick={() => handleNodeClick(customNode)}>
        <rect
          x={-125}
          y={-75}
          width="250"
          height="120"
          fill={theme.palette.background.paper}
          stroke={borderColor}
          strokeWidth="2"
          rx="10"
          ry="10"
          filter="drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1))"
        />

        <foreignObject x={-75} y={yPosition} width={190} height={50}>
          <div
            style={{
              fontSize: '14px',
              fontFamily: theme.typography.fontFamily,
              color: theme.palette.text.primary,
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              textOverflow: 'ellipsis',
              lineHeight: '1.4em',
            }}
          >
            {customNode.name}
          </div>
        </foreignObject>

        {type === 'Team' && (
          <>
            <text
              x={-110}
              y={-20}
              textAnchor="start"
              alignmentBaseline="middle"
              style={{
                fontSize: '12px',
                fontFamily: theme.typography.fontFamily,
                fill: theme.palette.text.secondary,
              }}
            >
              Owner: {owner}
            </text>

            <text
              x={-110}
              y={0}
              textAnchor="start"
              alignmentBaseline="middle"
              style={{
                fontSize: '12px',
                fontFamily: theme.typography.fontFamily,
                fill: theme.palette.text.secondary,
              }}
            >
              Team: {team}
            </text>

            <text
              x={-110}
              y={20}
              textAnchor="start"
              alignmentBaseline="middle"
              style={{
                fontSize: '12px',
                fontFamily: theme.typography.fontFamily,
                fill: theme.palette.text.secondary,
              }}
            >
              Status:
            </text>
            <circle
              cx={-60}
              cy={20}
              r="8"
              fill={statusColors[status] || '#000000'}
            />
          </>
        )}
      </g>
    );
  };

  const customPathFunc = (linkDatum: {
    source: { x: number; y: number };
    target: { x: number; y: number };
  }) => {
    const { source, target } = linkDatum;
    const path = `
      M${source.x},${source.y + 25}
      L${source.x},${(source.y + target.y) / 2}
      L${target.x},${(source.y + target.y) / 2}
      L${target.x},${target.y - 25}
    `;
    return path;
  };

  return (
    <Box sx={{ flexGrow: 1, padding: '20px' }}>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              border: '2px solid #0d47a1',
              mr: 1,
              borderRadius: '4px',
            }}
          ></Box>
          <Typography>Company Goals</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              border: '2px solid #388e3c',
              mr: 1,
              borderRadius: '4px',
            }}
          ></Box>
          <Typography>Team Goals</Typography>
        </Box>
      </Box>
      <div
        style={{
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {okrs ? (
          <Tree
            data={okrs}
            orientation="vertical"
            pathFunc={customPathFunc}
            translate={{ x: window.innerWidth / 2.5, y: 1 }}
            separation={{ siblings: 2, nonSiblings: 2 }}
            collapsible={true}
            zoomable={false}
            renderCustomNodeElement={renderNode}
            nodeSize={{ x: 200, y: 200 }}
          />
        ) : (
          <Typography>Loading...</Typography>
        )}
      </div>

      {/* Modal for OKR details */}
      {selectedOKR && (
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Edit OKR</DialogTitle>
          <DialogContent>
            <TextField
              label="Goal"
              name="Goal"
              value={selectedOKR.Goal}
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  Goal: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={selectedOKR.Status || ''}
                onChange={(e) =>
                  setSelectedOKR({
                    ...selectedOKR!,
                    Status: e.target.value as string,
                  })
                }
                label="Status"
              >
                <MenuItem value="Green">Green</MenuItem>
                <MenuItem value="Amber">Amber</MenuItem>
                <MenuItem value="Red">Red</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Deadline"
              name="TimePeriod"
              type="date"
              value={
                selectedOKR.TimePeriod
                  ? new Date(selectedOKR.TimePeriod).toISOString().split('T')[0]
                  : ''
              }
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  TimePeriod: e.target.value,
                })
              }
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Comments"
              name="Comments"
              value={selectedOKR.Comments || ''}
              onChange={(e) =>
                setSelectedOKR({
                  ...selectedOKR!,
                  Comments: e.target.value,
                })
              }
              fullWidth
              multiline
              rows={4}
              sx={{ mb: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              onClick={handleSaveOKR}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default OKRRelationships;
