// src/components/MainCards.tsx
import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import StatusPieChart from './PieChart';
import colors from './color';
import { OKR } from '../types'; // Import the OKR interface


// Props for the MainCardsLayout component
interface MainCardsLayoutProps {
  goalType: string;
  okrs: OKR[];
  selectedTeam: string;
  selectedUser: string;
  onOKRClick: (okr: OKR) => void;
}

// Props for the OKROverviewCard component
interface OKROverviewCardProps {
  goalType: string;
  okrs: OKR[];
  selectedTeam: string;
  onOKRClick: (okr: OKR) => void; // Add this line
}

// Props for the OKRStatusOverviewCard component
interface OKRStatusOverviewCardProps {
  okrs: OKR[];
}

// OKROverviewCard component
export function OKROverviewCard({
  goalType,
  okrs,
  selectedTeam,
  onOKRClick, // Destructure the onOKRClick prop
}: OKROverviewCardProps) {
  const nonCompletedOkrs = okrs.filter(
    (okr) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
  );

  // Group OKRs by Status
  const groupedOkrs: { [key: string]: OKR[] } = {
    Green: [],
    Amber: [],
    Red: [],
    'No Status Update': [],
  };

  nonCompletedOkrs.forEach((okr) => {
    if (okr.Status === 'Green') {
      groupedOkrs.Green.push(okr);
    } else if (okr.Status === 'Amber') {
      groupedOkrs.Amber.push(okr);
    } else if (okr.Status === 'Red') {
      groupedOkrs.Red.push(okr);
    } else {
      groupedOkrs['No Status Update'].push(okr);
    }
  });

  let title = `${goalType} OKRs`;
  if (goalType === 'Team' && selectedTeam !== 'All Teams') {
    title = `${selectedTeam} Team OKRs`;
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          {title}
        </Typography>
        {nonCompletedOkrs.length > 0 ? (
          <>
            {['Green', 'Amber', 'Red', 'No Status Update'].map((status) =>
              groupedOkrs[status].length > 0 ? (
                <Box key={status} sx={{ mt: 2 }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ color: colors[status] || 'black' }}
                  >
                    {status} OKRs
                  </Typography>
                  {groupedOkrs[status].map((okr, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      sx={{ mt: 1, ml: 2, cursor: 'pointer' }}
                      onClick={() => onOKRClick(okr)} // Call the onOKRClick handler
                    >
                      {okr.Goal}
                    </Typography>
                  ))}
                </Box>
              ) : null
            )}
          </>
        ) : (
          <Typography variant="body2" sx={{ mt: 1 }}>
            No OKRs found.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

// OKRStatusOverviewCard component
function OKRStatusOverviewCard({ okrs }: OKRStatusOverviewCardProps) {
  const nonCompletedOkrs = okrs.filter(
    (okr) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
  );

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Status Overview
        </Typography>
        <StatusPieChart okrs={nonCompletedOkrs} />
      </CardContent>
    </Card>
  );
}

// MainCardsLayout component
export const MainCardsLayout: React.FC<MainCardsLayoutProps> = ({
  goalType,
  okrs,
  selectedTeam,
  selectedUser,
  onOKRClick, // Accept the onOKRClick prop
}) => {
  return (
    <Box sx={{ flexGrow: 1, px: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <OKROverviewCard
            goalType={goalType}
            okrs={okrs}
            selectedTeam={selectedTeam}
            onOKRClick={onOKRClick} // Pass the onOKRClick handler
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <OKRStatusOverviewCard okrs={okrs} />
        </Grid>
      </Grid>
    </Box>
  );
};
